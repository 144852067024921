import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { NavigationExtras, Router } from '@angular/router';
import { catchError } from 'rxjs/operators';
import { apiResponseFactory } from '../models/response/apiResponseFactory';
import { ValidationErrorsHelper } from '../helper/ValidationErrorsHelper';
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  constructor(private router: Router, private toastr: ToastrService,
    private translate: TranslateService) { }

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    return next.handle(request).pipe(
      catchError((error) => {
        if (error) {
          switch (error.status) {
            case 400:
              if (error.url.includes('/registar-sredstava')) { break; }
              else if (error.url.includes('monri.com')) {
                this.toastr.error(error?.error?.message);
              }
              else {
                if (error.error.errors) {
                  var resp: apiResponseFactory = error.error;
                  if (resp) {
                    if (resp.isValidationError == true) {
                      throw ValidationErrorsHelper(resp.validationErrors).flat();
                      /*  var errs = ValidationErrorsHelper(resp.validationErrors).flat();
                       errs.forEach(item => {
                         this.toastr.error(item);
                       }); */
                    } else if (resp.errors.length > 0) {
                      if (resp.errors.flat().toString().includes("PopisAlreadyExists")) break;
                      this.toastr.error(resp.errors.flat().toString());
                    }
                  } else throw error;
                } else if (typeof error.error === 'object') {
                  this.toastr.error(error.statusText, error.status);
                } else {
                  this.toastr.error(error.error, error.status);
                }
              }
              break;

            case 401:
              this.toastr.error(error.error, error.status);
              break;

            case 403:
              this.toastr.error(this.translate.instant("MESSAGE.Nemate dozvolu za pristup trenutnoj stavki"));
              break;

            case 404:
              this.router.navigateByUrl('/error/error-404').then();
              break;

            case 406:
              this.toastr.error(this.translate.instant("MESSAGE.Nemate dozvolu za pristup firmi"));
              break;

            case 422:
              if (error?.url) {
                if (error?.url?.toString().includes("monri.com")) {
                  var errors = error?.error?.errors;
                  if (errors) {
                    errors.forEach(element => {
                      this.toastr.error(element);
                    });
                  }
                }
              }
              break;

            case 500:
              // const navigationExtras: NavigationExtras = { state: { error: error.error } };
              this.toastr.error(
                '500:' + this.translate.instant("MESSAGE.Došlo je do greške")
              );
              //this.router.navigateByUrl('/error/error-500', navigationExtras).then();
              break;

            case 429:
              this.toastr.error(this.translate.instant("MESSAGE.Molim usporite"));
              break;

            default:
              this.toastr.error(this.translate.instant("MESSAGE.Došlo je do greške"));
              break;
          }
        }
        return throwError(error);
      })
    );
  }
}
