import {
  HttpErrorResponse,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';

import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { catchError, filter, switchMap, take } from 'rxjs/operators';
import {
  AccessToken,
  RefreshToken,
} from '../models/login/AuthenticateResultDto';
import { AccountService } from '../shared/services/account.service';

const TOKEN_HEADER_KEY = 'Authorization';
const DEVICE_TYPE = 'device-type';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  private isRefreshing = false;
  private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(
    null
  );
  private accessToken: AccessToken;
  private refreshToken: RefreshToken;

  constructor(private accountService: AccountService) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<any> {
    let deviceType: any = this.getDeviceType();

    let authReq = req;
    this.accessToken = this.accountService.accessToken;

    const token = this.accessToken?.token;
    if (token != null) {
      authReq = this.addTokenHeader(req, token);
    }

    const type = deviceType;
    if (type != null) {
      authReq = this.addDeviceTypeHeader(req, deviceType);
    }

    return next.handle(authReq).pipe(
      catchError((error) => {
        if (
          error instanceof HttpErrorResponse &&
          !authReq.url.includes('/login') &&
          (error.status == 401 || error.status == 403)
        ) {
          if (error.status == 403) {
            return throwError(error);
          } else {
            return this.handle401Error(authReq, next);
          }
        }
        return throwError(error);
      })
    );
  }

  private handle401Error(request: HttpRequest<any>, next: HttpHandler) {
    if (!this.isRefreshing) {
      this.isRefreshing = true;
      this.refreshTokenSubject.next(null);
      this.refreshToken = this.accountService.getRefreshToken();
      const token = this.refreshToken.token;
      if (token) {
        return this.accountService.refreshToken(token).pipe(
          switchMap((auth: any) => {
            this.isRefreshing = false;
            this.refreshTokenSubject.next(auth.result.accessToken.token);
            this.accountService.setAccessToken(auth.result.accessToken);
            this.accountService.setCurrentUser(auth.result.accountInfo);
            this.accountService.setRefreshToken(auth.result.refreshToken);
            return next.handle(
              this.addTokenHeader(request, auth.result.accessToken.token)
            );
          }),
          catchError((err) => {
            this.accountService.logout();
            this.isRefreshing = false;
            return throwError(err);
          })
        );
      }
    }

    return this.refreshTokenSubject.pipe(
      filter((token) => token !== null),
      take(1),
      switchMap((token) => next.handle(this.addTokenHeader(request, token)))
    );
  }

  private addDeviceTypeHeader(request: HttpRequest<any>, deviceType: string) {
    let headers = request.headers

    var url = (request.url == null) ? "" : request.url;
    if (url.startsWith("http://")) { }
    else if (url.includes("monri")) { }
    else {
      headers = headers.set(DEVICE_TYPE, deviceType);
    }

    var acceptLanguageHeder = request.headers.getAll("Accept-Language");
    if (acceptLanguageHeder == null) {
      headers = headers.set('Accept-Language', 'hr-HR');
    }

    return request.clone({
      headers: headers,
    });
  }

  private addTokenHeader(request: HttpRequest<any>, token: string) {
    let headers = request.headers.set(TOKEN_HEADER_KEY, 'Bearer ' + token);
    return request.clone({
      headers: headers,
    });
  }

  getDeviceType() {
    const ua = navigator.userAgent;
    if (/(ipad|iP(hone|od))/i.test(ua)) {
      return 'ios';
    }
    if (
      /Mobile|Android|BlackBerry|tablet|playbook|silk|IEMobile|Kindle|Silk-Accelerated|(hpw|web)OS|(android(?!.*mobi))|Opera M(obi|ini)/.test(
        ua
      )
    ) {
      return 'android';
    }
    return 'desktop';
  }
}
