export const environment = {
  production: true,
  apiUrl: "https://api.fi-we.com/api/",
  hubUrl: "https://api.fi-we.com/hubs/",
  monriToken: "a794fff582b758d7cdac4efb6bc52f9e329aa446",
  monriKey: 'key-1470d897e441f036f8b18f1121755d7d',
  monriUrl: 'https://ipgtest.monri.com/services-demo/proxy/v2/',
  firebase: {
    apiKey: "AIzaSyCJi0cQ866lzPR8hYJUs77JUmD4DBpswl8",
    authDomain: "angular-deomo.firebaseapp.com",
    databaseURL: "https://angular-deomo.firebaseio.com",
    projectId: "angular-deomo",
    storageBucket: "angular-deomo.appspot.com",
    messagingSenderId: "175907956497"
  }
};
